import request from '../../utils/request'

export default class MenuRequest {
  // 获取列表
  async listPost (data) {
    return await request.post('/System/Menu/Select', data)
  }

  // 添加操作
  async addPost (data) {
    return request.post('/System/Menu/Insert', data)
  }

  // 编辑操作
  async editPost (data) {
    return request.post('/System/Menu/Edit', data)
  }

  // 删除操作
  async deletePost (data) {
    return request.post('/System/Menu/Delete', data)
  }

  // 刷新表结构
  async GetUserMenu (data) {
    return request.post('/System/Menu/GetUserMenu', data)
  }
}
