import { render, staticRenderFns } from "./CommonHead.vue?vue&type=template&id=9a51647c&scoped=true"
import script from "./CommonHead.vue?vue&type=script&lang=js"
export * from "./CommonHead.vue?vue&type=script&lang=js"
import style0 from "./CommonHead.vue?vue&type=style&index=0&id=9a51647c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a51647c",
  null
  
)

export default component.exports