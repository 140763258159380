<template>
  <div>
    <el-container >
        <!-- 左侧导航栏 -->
      <el-aside width="auto" background-color="#545c64">
        <CommonLeft></CommonLeft>
      </el-aside>

      <!-- 右侧主体区域 -->
      <el-container>
        <!-- 头部 -->
        <el-header>
            <CommonHead></CommonHead>
        </el-header>

        <!-- tag路由标签 -->
        <CommonTag></CommonTag>

        <!-- 内容区域 -->
        <el-main>
          <!-- 子路由出口 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonLeft from '../../components/CommonLeft.vue'
import CommonHead from '../../components/CommonHead.vue'
import CommonTag from '../../components/CommonTag.vue'

export default {
  name: 'Main',
  components: { CommonLeft, CommonHead, CommonTag }
}
</script>

<style lang="less" scoped>
.el-header{
  padding: 0;
}

/deep/ .el-main{
  // !？？？？为什么height给任意高度就可以使用？
  height: 5px;
  overflow: auto;
}

// 去除滚动条
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
