<template>
  <div class="element">
    <el-menu
      class="el-menu-vertical-demo"
      :data="menuData"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#409EFF"
    >
      <h3 @click="homeClick">{{ title }}</h3>

      <!-- 渲染有子菜单的菜单 -->
      <el-submenu v-for="(item, index) in hasChildren" :key="item.name" :index="index+'-'">
        <template slot="title">
          <i :class="`el-icon-${item.icon}`"></i>
          <span slot="title">{{ item.name }}</span>
        </template>
        <!-- 子菜单 -->
        <el-menu-item-group
          v-for="subItem in item.children"
          :key="subItem.path">
          <el-menu-item :index="subItem.path" @click="clickMenu(subItem)" class="el-menu-item">
            <i :class="`el-icon-${subItem.icon}`"></i>
            <span slot="title">{{ subItem.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!-- 渲染没有子菜单的菜单 -->
      <el-menu-item
        :index="item.name"
        v-for="item in noChildren"
        :key="item.name"
        @click="clickMenu(item)"
      >
        <!-- 使用模板字符串拼接变量 -->
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>

    </el-menu>
  </div>
</template>

<style lang="less">
.element {
  display: flex;
}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
  // background-color:#F5F5F5;
  background-color: rgba(84, 92, 100, 0.9);
}

/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  // background-color:#F5F5F5;
  // background-color: rgba(84, 92, 100,.3);
}

/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

// !上面是修改滑动条代码

// !下面是页面css
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100vh;
}

.el-menu {
  user-select: none;
  border-right: 0;

  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>

<script>
import MenuRequest from '@/request/System/MenuRequest'

const request = new MenuRequest()

export default {
  data () {
    return {
      title: '建站系统',
      menuData: []
    }
  },
  created: async function () {
    const menuSession = sessionStorage.getItem('menu')
    if (menuSession != null) {
      this.menuData = JSON.parse(menuSession)
      return
    }
    const result = await request.GetUserMenu({
      page: 1,
      limit: 100000
    })
    if (result.code !== 200) return
    this.menuData = result.data
    sessionStorage.setItem('menu', JSON.stringify(result.data))
  },
  methods: {
    // 展开左侧导航事件
    handleOpen: function (key, keyPath) {
    },
    // 收缩导航
    handleClose: function (key, keyPath) {
    },
    homeClick: function () {
      // 判断当前路径是否和点击的菜单路径一致
      if (this.$route.path === '/SystemHome') return
      this.$router.push('/')
    },
    // 点击进行跳转
    clickMenu: function (item) {
      if (this.$route.path !== item.path) this.$router.push(item.path)

      this.$store.commit('selectMenu', item)
    }
  },
  computed: {
    // 没有子菜单
    noChildren () {
      return this.menuData.filter((item) => !item.children)
    },

    // 有子菜单
    hasChildren () {
      return this.menuData.filter((item) => item.children)
    },

    isCollapse () {
      // 注意这里的tab是小仓库名
      return this.$store.state.tab.isCollapse
    }
  }
}
</script>
