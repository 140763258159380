<template>
  <div class="header-container">
    <div class="l-content">
      <!--      <el-button
              icon="el-icon-menu"
              size="mini"
              @click="handleMenu"
              style="margin-right: 20px"
            ></el-button>-->
    </div>
    <div class="r-content">
      <!-- 下拉菜单 -->
      <el-row>
        <el-col :span="8">
          <div class="screen">
            <FullScreenVue></FullScreenVue>
          </div>
        </el-col>
        <el-col :span="8">
          <el-dropdown @command="handleClick">
            <span class="el-dropdown-link">
              <img src="../assets/staffPhoto.png" alt="" class="user"/>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="clearLocalStorage">清除缓存</el-dropdown-item>
              <el-dropdown-item command="cancel">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import FullScreenVue from './FullScreen.vue'

export default {
  components: { FullScreenVue },
  data () {
    return {}
  },
  methods: {
    handleMenu () {
      this.$store.commit('collapseMenu')
    },
    handleClick (command) {
      if (command === 'clearLocalStorage') {
        localStorage.clear()
        location.reload()
      } else {
        this.$message({
          message: '登出',
          type: 'warning'
        })
        // 清除cookie
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('menu')
        // 跳转到登录页面
        this.$router.push('/login')
      }
    }
  },
  watch: {
    $route (val) {
    }
  },
  computed: {}
}
</script>

<style lang="less" scoped>
.header-container {
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .text {
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
  }

  .r-content {
    width: 150px;

    .screen {
      line-height: 60px;
      font-size: 25px;
    }

    .user {
      margin-top: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
    }
  }

  .l-content {
    display: flex;
    align-items: center;

    /deep/ .el-breadcrumb__item {
      .el-breadcrumb__inner {
        &.is-link {
          color: aqua;
        }
      }

      &:last-child {
        .el-breadcrumb__inner {
          color: #fff;
        }
      }
    }
  }
}
</style>
