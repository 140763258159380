<template>
  <div class="tabs">
    <el-tag
      v-for="(item, index) in tags"
      :key="item.path"
      :closable="item.name !== 'home'"
      :effect="$route.name === item.name ? 'dark' : 'plain'"
      @click="changeMenu(item)"
      @close="handleClose(item, index)"
      >{{ item.name }}</el-tag
    >
  </div>
</template>

<script>
export default {
  name: 'CommonTag',
  data () {
    return {}
  },
  computed: {
    tags () {
      return this.$store.state.tab.tabList
    }
  },
  methods: {
    // 点击tag标签进行页面跳转
    changeMenu (item) {
      //   this.$router.push(item.path)
      this.$router.push({ path: item.path })
    },

    // 删除tag标签
    handleClose (item, index) {
      // 调用vux里的删除方法
      this.$store.commit('closeTag', item)

      //   进行删除之后的跳转
      //   删除的非当前激活项
      if (item.name !== this.$route.name) {
      }
      //   删除的是最后一项，向前一个跳转
      // 因为先删除，所以tags.length是删除后的长度
      if (index === this.tags.length) {
        this.$router.push({
          name: this.tags[index - 1].name
        })
      } else {
        //  删除的不是最后一项，向后一个跳转
        this.$router.push({
          name: this.tags[index].name
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tabs {
  padding: 20px;
  .el-tag {
    cursor: pointer;
    margin: 10px 15px 0 0;
  }
}
</style>
