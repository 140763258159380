<template>
  <div>
    <i style="cursor:pointer;color:#409eff" :class="isFullscreen ? 'el-icon-rank' : 'el-icon-full-screen'" @click="toggleFullscreen"></i>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isFullscreen: false
    }
  },
  methods: {
    toggleFullscreen () {
      if (!this.isFullscreen) {
        document.documentElement.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }
  },
  mounted () {
    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = !!document.fullscreenElement
    })
  }
}
</script>
